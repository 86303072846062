.br-m { display: none; }
.br-l { display: none; }
.br-xl { display: none; }
.br-s { display: block; }

@media screen and (min-width: 480px) {
    .br-s { display: none; }
    .br-l { display: none; }
    .br-xl { display: none; }
    .br-m { display: block; }
}

@media screen and (min-width: 768px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-xl { display: none; }
    .br-l { display: block; }
}

@media screen and (min-width: 1025px) {
    .br-s { display: none; }
    .br-m { display: none; }
    .br-l { display: none; }
    .br-xl { display: block; }
}

@media screen and (min-width: 1025px) {
    .primary-logo {
        margin-left: 5.6%;
    }
}

.primary-logo {
    @include media-breakpoint-up(mobile-menu) {
        background-color: $brand-primary;
    }
}

div.top-banner {
    div.header-wrapper {
        .primary-logo {
            width: 176px;

            @include media-breakpoint-up(md) {
                width: 200px;
                height: 52px;
            }
        }
    }
}

@include media-breakpoint-up(mobile-menu) {
    div.top-banner {
        div.header-wrapper {
            .header-search {
                top: -55px;
            }

            @include media-breakpoint-up(xl) {
                .primary-logo {
                    width: 330px;
                }
            }

            padding-top: 50px;
            height: 130px;
            background-color: $brand-primary;

            a.user-account {
                color: white;
            }

            .user-info {
                margin-top: 40px;
            }

            #mini-cart {
                top: 40px;

                .minicart-quantity {
                    color: white;
                }
            }

            svg.icon.arrow,
            svg.icon.cart {
                fill: white;
                background-color: $brand-primary;
            }
        }

        &.simplified {
            color: white;

            div.header-wrapper {
                padding-bottom: 20px;
            }

            svg {
                fill: white;
            }
        }
    }

    .checkout-section.shipping-delay {
        .field-wrapper > label {
            display: flex;
            flex-wrap: nowrap;
            width: auto;

            .icon {
                margin-left: 5px;
            }
        }

        svg.tooltip-icon {
            margin: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .add-to-cart-dialog.ui-dialog {
        .mini-cart-totals {
            .atc-actions {
                .mini-cart-link-checkout {
                    width: 50%;
                }
            }
        }
    }
}

.simplified-mini-cart .mini-cart-link span.minicart-quantity {
    color: $dark-gray;
}

@include media-breakpoint-up(lg) {
    .simplified-mini-cart .mini-cart-link {
        flex-flow: row nowrap;

        span.minicart-quantity {
            color: $white;
        }
    }
}

.icon.store-card {
    border: 1px solid black;
    border-radius: 6px;
}
